import React from 'react';

import Layout from '../components/Layout';
import pic1 from '../assets/images/01.jpg';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

const About = () => (
  <Layout>
    <div id="main" className="box">
      <div className="box inner">
        <h1>About Us</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>

        <article className="box">
        <h3>Our history</h3>
        <p>
          We started out as a small group of friends with a vision to create revolutionary products.
          We have grown into a 
        </p><br />

        </article>
        <article className="box"><h3>Our Method</h3>
        <p>
          Millions of businesses use the Internet as a cost-effective communications channel. 
          It lets them exchange information with their target market and make fast, secure transactions. 
          However, effective engagement is only possible when the business is able to capture and store all the necessary data, 
          and have a means of processing this information and presenting the results to the user We believe in 
          providing right solutions to our customers at the right price for SME's (small and medium size enterprises) to large corporations. 
          SVATVA is dedicated to provide quality services to all its customers.
        </p>
        <Row><Col>
{/*         <Card>
          <Card.Header>Software Application Development</Card.Header>
            <Card.Body>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus
                rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh
                porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc
                ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit
                sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris,
                fringilla in aliquam at, euismod in lectus. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. In non lorem sit amet elit placerat maximus. Pellentesque
                aliquam maximus risus, vel venenatis mauris vehicula hendrerit.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Software Application Development</Card.Header>
            <Card.Body>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus
                rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh
                porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc
                ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit
                sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris,
                fringilla in aliquam at, euismod in lectus. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. In non lorem sit amet elit placerat maximus. Pellentesque
                aliquam maximus risus, vel venenatis mauris vehicula hendrerit.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Software Application Development</Card.Header>
            <Card.Body>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus
                rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh
                porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc
                ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit
                sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris,
                fringilla in aliquam at, euismod in lectus. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. In non lorem sit amet elit placerat maximus. Pellentesque
                aliquam maximus risus, vel venenatis mauris vehicula hendrerit.
              </p>
            </Card.Body>
          </Card> */}
          </Col></Row>
        </article>
{/*         <article className="box"><h3>Our Team</h3>
        <p>
          Interdum et malesuada fames ac ante ipsum primis in faucibus.
          Pellentesque venenatis dolor imperdiet dolor mattis sagittis. Praesent
          rutrum sem diam, vitae egestas enim auctor sit amet. Pellentesque leo
          mauris, consectetur id ipsum sit amet, fersapien risus, commodo eget
          turpis at, elementum convallis elit. Pellentesque enim turpis,
          hendrerit tristique lorem ipsum dolor.
        </p>
        <div >
            <Link to="/SvatvaTeam" className="button">
                Learn More
            </Link>
          </div>
        </article> */}
        <article >
        
        </article>
      </div>
    </div>
  </Layout>
);

export default About;
